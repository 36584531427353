import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { ClassNamesConfig, ControlProps, DropdownIndicatorProps, OptionProps } from 'react-select';

export type FormSelectListProps = {
  callBackChange: (arg0: any) => void;
  callBackInput?: (arg0: any) => void;
  className?: string;
  disabled?: boolean;
  isClearable?: boolean;
  defaultValue?: any;
  errorMessage?: string;
  name?: string;
  id?: string;
  isInvalid?: boolean;
  label?: string;
  onClose?: () => void;
  onOpen?: () => void;
  options: any[];
  placeholder?: string;
  value?: any;
  isSearchable?: boolean;
};

const FormSelectList = ({
  callBackChange,
  callBackInput,
  className,
  defaultValue,
  disabled,
  id,
  isClearable,
  errorMessage,
  isSearchable = true,
  isInvalid,
  label,
  name,
  onClose,
  onOpen,
  options,
  placeholder,
  value,
}: FormSelectListProps) => {
  const selectStyles = {
    valueContainer: () => '!p-3 !flex',
    control: (state: ControlProps<string, false, any>) =>
      `${isInvalid && isTouched ? '!border-error-light !text-error-dark' : ''} ${
        state.isFocused ? '!border !border-blue !ring-1 !ring-blue' : 'border border-gray-light'
      }`,
    option: (state: OptionProps<string, false, any>) =>
      `transition-all ease-in-out bg-white hover:bg-gray-light focus:bg-gray-light ${
        state.isFocused || state.isSelected ? '!bg-gray-light' : '!bg-white'
      } ${state.isSelected ? '!text-blue' : '!text-gray-darkest'} cursor-pointer`,
    menu: () => '!mt-[6px]',
    indicatorSeparator: () => 'hidden',
    dropdownIndicator: (state: DropdownIndicatorProps<string, false, any>) =>
      state.isFocused ? '!text-blue-navy' : '!text-blue',
    input: () => 'after:content-[""] after:hidden !block !w-full',
    placeholder: () => 'absolute !text-dark-gray',
    singleValue: () => 'absolute',
  };

  const [isTouched, setIsTouched] = useState<boolean>(false);

  const Select = dynamic(() => import('react-select'));

  return (
    <>
      {label && (
        <label className={classNames('text-gray-dark', 'text-sm', 'mb-2', 'block')} htmlFor={id}>
          {label}
        </label>
      )}
      <Select
        inputId={id}
        defaultValue={defaultValue}
        openMenuOnFocus
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={disabled}
        aria-invalid={isInvalid}
        className={className}
        classNamePrefix={'az-blue-select-list'}
        options={options}
        onBlur={() => {
          setIsTouched(true);
        }}
        onChange={callBackChange}
        onInputChange={callBackInput}
        onMenuOpen={onOpen}
        onMenuClose={onClose}
        value={value}
        name={name}
        placeholder={placeholder}
        classNames={selectStyles as ClassNamesConfig<unknown, boolean, any>}
      />
      <span
        className={classNames('text-xs', 'text-error', 'top-full', 'left-0', 'absolute', {
          hidden: !isInvalid || !isTouched,
          block: isInvalid && isTouched,
        })}
      >
        {errorMessage}
      </span>
    </>
  );
};

export default FormSelectList;
