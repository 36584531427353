// Global
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { LayoutTwoColumnShell } from 'components/authorable/layout/LayoutComponents/LayoutTwoColumn/LayoutTwoColumn';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import RichTextA11yWrapper from '../RichTextA11yWrapper/RichTextA11yWrapper';

export type HeadingLevels = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type HeadingSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type HeadingPositionProps = 'left' | 'center' | 'split';

export interface HeadingProps {
  bold?: boolean;
  center?: boolean;
  description?: Field<string>;
  descriptionRTE?: Field<string>;
  descriptionClassName?: string;
  editable?: boolean;
  eyebrow?: Field<string>;
  eyebrowClassName?: string;
  headingClassName?: string;
  headingLevel: HeadingLevels | string;
  headingSize?: HeadingSizes | string;
  headingSplitPostion?: HeadingPositionProps | string;
  headingText?: Field<string>;
  headingTextBold?: Field<string>;
  id?: string;
  headingIsRichText?: boolean;
  headingStyle?: string | undefined;
}

export type HeadingTagProps = {
  children: React.ReactNode | React.ReactNode[];
  headingLevel: HeadingLevels;
  offset?: number;
  [name: string]: unknown;
};

const HeadingTag = ({ children, headingLevel, ...props }: HeadingTagProps): JSX.Element => {
  const Tag = headingLevel || 'h3';

  return <Tag {...props}>{children}</Tag>;
};

const Heading = ({
  bold,
  center,
  description,
  descriptionRTE,
  descriptionClassName,
  editable,
  eyebrow,
  eyebrowClassName,
  headingClassName,
  headingLevel,
  headingSize,
  headingSplitPostion,
  headingText,
  headingTextBold,
  id,
  headingIsRichText,
  headingStyle,
}: HeadingProps): JSX.Element => {
  const hasHeadingText = headingText?.value;
  const hasHeadingTextBold = headingTextBold?.value;
  const hasDescription = description?.value || descriptionRTE?.value;
  const hasEyebrow = eyebrow?.value;
  //const validBoldHeader = ['h1', 'h2', 'h3'].some((headingItem) => headingItem === headingLevel);
  const alignmentOptions = headingSplitPostion;

  const isEE = useExperienceEditor();

  if (!isEE && !hasHeadingText && !hasHeadingTextBold && !hasDescription && !hasEyebrow) {
    return <></>;
  }

  const headingClasses = classNames(
    'max-w-prose',
    {
      'font-bold': bold && ['h1', 'h2', 'h3'].some((headingItem) => headingItem === headingLevel),
      'font-light': !bold && ['h1', 'h2', 'h3'].some((headingItem) => headingItem === headingLevel),
      'mb-4': hasDescription && !headingClassName,
      'mb-8': !hasDescription && !headingClassName,
      // Referrence for below ==== https://dev.azure.com/AZBlue/OneAZBlue/_workitems/edit/355745
      // 'last:mb-0': !hasDescription && !headingClassName && headingSize,
      [`${headingClassName}`]: headingClassName?.length,
      'heading-2xs': headingSize === 'xxs',
      'heading-xs': headingSize === 'xs',
      'heading-sm': headingSize === 'sm',
      'heading-md': headingSize === 'md',
      'heading-lg': headingSize === 'lg',
      'heading-xl': headingSize === 'xl' || !headingSize,
      'text-center': center || alignmentOptions === 'center',
    },
    headingStyle
  );

  const renderHeadingTag = () => {
    return (
      <>
        {(isEE || eyebrow?.value || eyebrow?.value !== ' ') && (
          <Text
            tag="span"
            field={eyebrow}
            className={classNames(
              eyebrowClassName,
              {
                'text-center': center || alignmentOptions === 'center',
              },
              { ' block': center || alignmentOptions === 'center' }
            )}
          />
        )}
        {isEE || hasHeadingText || hasHeadingTextBold ? (
          <HeadingTag
            className={headingClasses}
            component-name="helpers/heading"
            data-testid="heading-heading-element"
            headingLevel={headingLevel as HeadingLevels}
            id={id}
          >
            {headingIsRichText ? (
              <RichTextA11yWrapper field={headingText} editable={editable} />
            ) : (
              <Text tag="" field={headingText} editable={editable} />
            )}
            {isEE || hasHeadingTextBold ? (
              <span className={classNames('font-bold')}>
                {' '}
                {headingIsRichText ? (
                  <RichTextA11yWrapper field={headingTextBold} editable={editable} />
                ) : (
                  <Text tag="" field={headingTextBold} editable={editable} />
                )}
              </span>
            ) : null}
          </HeadingTag>
        ) : (
          <></>
        )}
      </>
    );
  };
  const renderHeadingDescription = () => {
    return (
      <>
        {isEE || hasDescription ? (
          <div
            className={classNames(
              'p-0',
              'rte',
              { 'text-center': center || alignmentOptions === 'center' },
              { 'text-lg': alignmentOptions === 'right' || alignmentOptions === 'left' },
              descriptionClassName
            )}
          >
            <RichTextA11yWrapper field={description} editable />
            <RichTextA11yWrapper field={descriptionRTE} editable />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      {alignmentOptions === 'split' ? (
        <LayoutTwoColumnShell
          dataComponent="helpers/heading/heading"
          left={renderHeadingTag()}
          right={renderHeadingDescription()}
        />
      ) : (
        <div>
          {renderHeadingTag()}
          {renderHeadingDescription()}
        </div>
      )}
    </>
  );
};

export default Heading;
