// Global
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
//import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import CtaGroup, { CtaAlignment } from 'components/helpers/CtaGroup/CtaGroup';
import Heading from 'components/helpers/Heading/Heading';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import { getEnum, getEnumValueOverRide } from 'lib/get-enum';
import { Theme } from 'lib/get-theme';

// Local

export type BannerProps = Sitecore.Override.ComponentBase & Feature.Components.Promo.Fields.Banner;
export type BannerPositionProps = 'left' | 'center' | 'right';

const Banner = ({ fields, rendering }: BannerProps): JSX.Element => {
  const componentTheme = getEnum<Theme>(fields?.backgroundStyle);
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const alignmentOptions = getEnum<BannerPositionProps>(fields?.alignment);

  const textAlignment = classNames({
    'text-left': alignmentOptions === 'left' || alignmentOptions === 'right',
    'text-center': alignmentOptions === 'center',
  });
  const contentAlignment = classNames(
    {
      'items-start': alignmentOptions === 'left' || alignmentOptions === 'right',
      'items-center': alignmentOptions === 'center',
    },
    {
      'ml-auto': alignmentOptions === 'right',
      'mx-auto': alignmentOptions === 'center',
      'mr-auto': alignmentOptions === 'left',
    }
  );
  const componentAnchorId = rendering?.uid;
  return (
    <Container
      theme={componentTheme || 'theme-blue'}
      dataComponent="authorable/promo/banner"
      className={classNames(
        'relative',
        'py-16',
        'md:py-22',
        'overflow-hidden',
        'container-content-padding-x',
        'anchorIdIndentifier',
        'promo-banner'
      )}
      id={componentAnchorId}
    >
      <div>
        {fields?.backgroundImage?.value?.src && (
          <div
            className={classNames(
              'md:absolute',
              'bottom-0',
              'left-0',
              'relative',
              '-mx-5',
              'md:mx-0',
              '-mt-16',
              'md:mt-0',
              'md:w-full',
              'z-0'
            )}
          >
            <ImageWrapper layout="responsive" field={fields?.backgroundImage as SizedImageField} />
          </div>
        )}
        <div
          className={classNames(
            'flex',
            'flex-col',
            'justify-center',
            'max-w-xl',
            'relative',
            'w-full',
            'z-10',
            contentAlignment
          )}
        >
          <Heading
            headingText={fields?.title}
            headingClassName={classNames(textAlignment, 'w-full')}
            headingLevel="h3"
            headingSize="md"
            description={fields?.description}
            descriptionClassName={classNames('mb-8', textAlignment)}
          />
          <CtaGroup
            {...fields}
            ctaAlignment={getEnumValueOverRide<CtaAlignment>(`${alignmentOptions as CtaAlignment}`)}
          />
        </div>
      </div>
    </Container>
  );
};

export default Banner;
