import axios, { AxiosResponse } from 'axios';
import { CPTCodeRecord } from 'lib/server-utils/nexus-api';
import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';

const fetchCPTCodes = async (CPTCode: string): Promise<AxiosResponse<CPTCodeRecord[]>> => {
  console.log('CPTCode===>', CPTCode);
  try {
    const response = await axios.get<CPTCodeRecord[]>(
      '/api/nexus/ProcedureCode?' + new URLSearchParams([['CPTCode', CPTCode]])
    );

    console.log('response===>', response);

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Re-throw the error to maintain the expected return type
  }
};

interface AutocompleteDropdownProps {
  searchIcon: string | undefined;
}

const AutocompleteDropdown: React.FC<AutocompleteDropdownProps> = ({ searchIcon }) => {
  const [val, setVal] = useState('');
  const [cptCode, setCPTCode] = useState<CPTCodeRecord[]>([]);
  const [loading, setLoading] = useState(false);
  // console.log('autocomplete searchIcon===>', searchIcon);

  useEffect(() => {
    console.log('cptCode===>', cptCode);
  }, [cptCode]);

  useEffect(() => {
    if (val && val.length > 2) {
      setLoading(true);
      fetchCPTCodes(val).then((response: AxiosResponse<CPTCodeRecord[]>) => {
        // To take only first 20 results
        //const filteredCpt = data?.slice(0, 20);
        const filteredCpt: CPTCodeRecord[] = Array.isArray(response?.data)
          ? response?.data.slice(0, 20)
          : [];
        console.log('data===>', response?.data);
        setCPTCode(filteredCpt);
        setLoading(false);
      });
    } else {
      setCPTCode([]);
    }
  }, [val]);

  return (
    <>
      <div className="autocomplete-wrapper">
        <Autocomplete
          value={val}
          items={cptCode}
          getItemValue={(item) => item.CPTCode}
          shouldItemRender={(item, val) => item.CPTCode.toLowerCase().includes(val.toLowerCase())}
          renderMenu={(items) => (
            <div className="dropdown">
              {items.length === 0 ? <div className="no-records">NO RECORDS FOUND</div> : items}
            </div>
          )}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? 'selected-item' : ''}`}
            >{`${item.CPTCode} - ${item.Description}`}</div>
          )}
          onChange={(_event, val) => setVal(val)}
          onSelect={(val) => setVal(val)}
          inputProps={{
            placeholder: 'Type in a Keyword or CPT code',
            className:
              'border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-[483px]',
          }}
        />
        {loading ? (
          <div className="loading">loading...</div>
        ) : (
          // eslint-disable-next-line @next/next/no-img-element
          <img src={searchIcon} className="search-icon" />
        )}
      </div>
    </>
  );
};

export default AutocompleteDropdown;
