import classNames from 'classnames';

export type Theme =
  | 'theme-white'
  | 'theme-blue'
  | 'theme-blue--light'
  | 'theme-blue--navy'
  | 'theme-blue--peacock'
  | 'theme-split--blue-white'
  | 'theme-gray'
  | 'theme-inherit'
  | 'theme-prosano-blue'
  | 'theme-prosano-white'
  | 'theme-prosano-blue--navy'
  | 'theme-prosano-blue--light'
  | 'heading-theme-black'
  | 'heading-theme-white'
  | 'heading-theme-blue';

export const ThemeOptionsDefault: Theme = 'theme-white';

export const getThemeClasses = (theme: Theme | undefined): string =>
  theme === 'theme-inherit' || !theme ? '' : classNames(theme, 'bg-theme-bg', 'text-theme-text');
