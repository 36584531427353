// Global
import classNames from 'classnames';

// Lib
import { getThemeClasses, Theme } from 'lib/get-theme';

// Local
import Container from 'components/helpers/Container/Container';
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import { getEnum } from 'lib/get-enum';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Feature } from '.generated/templates/Feature.Items.model';
import { MissingDataSource } from 'components/helpers/EditingHelpText';

import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import HeadingWrapper, {
  HeadingWrapperProps,
} from 'components/helpers/HeadingWrapper/HeadingWrapper';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import { ImageField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export type LayoutPageSectionProps = Sitecore.Override.ComponentBase &
  Feature.Components.PageLayouts.Fields.PageSection;

export type LayoutPageSectionShellProps = HeadingWrapperProps & {
  theme?: Theme;
  children: React.ReactNode;
  additionalClassNames?: string;
  innerClassNames?: string;
  dataComponent?: string;
  innerRef?: LegacyRef<HTMLDivElement>;
  backgroundImage?: ImageField;
  backgroundMobileImage?: ImageField;
  backgroundDamImage?: ImageField;
  backgroundMobileDamImage?: ImageField;
};

export const LayoutPageSectionShell = ({
  children,
  additionalClassNames,
  innerClassNames,
  dataComponent,
  theme,
  innerRef,
  ...fields
}: LayoutPageSectionShellProps): JSX.Element => {
  const overlapClassNames = innerClassNames ? innerClassNames : '';
  const isSplitTheme = theme?.includes('split');

  const context = useSitecoreContext();
  const siteName = context.sitecoreContext.site?.name?.toLocaleLowerCase();
  const headerClassName = siteName === 'prosanohealth' ? 'prosanohealth-header-element' : '';

  // Determine which image field to use for the md-lg background. Dam image takes priority.
  const backgroundImageField = fields.backgroundDamImage?.value?.src
    ? fields.backgroundDamImage
    : fields.backgroundImage?.value?.src
    ? fields.backgroundImage
    : null;
  // Determine which image field to use for the mobile background. Dam image takes priority.
  const backgroundMobileImageField = fields.backgroundMobileDamImage?.value?.src
    ? fields.backgroundMobileDamImage
    : fields.backgroundMobileImage?.value?.src
    ? fields.backgroundMobileImage
    : null;

  // Check if there is a valid background image
  const hasBackgroundImage =
    backgroundImageField?.value?.src !== undefined && backgroundImageField?.value?.src !== null;

  // Check if there is a valid background mobile image
  const hasBackgroundMobileImage =
    backgroundMobileImageField?.value?.src !== undefined &&
    backgroundMobileImageField?.value?.src !== null;

  return (
    <div
      className={classNames(
        'bg-cover',
        'bg-no-repeat',
        'flex',
        'flex-col',
        'flex-grow',
        'shrink-0',
        'justify-end',
        'md:justify-center',
        'md:min-h-[565px]',
        'overflow-hidden',
        'relative'
      )}
    >
      {hasBackgroundImage && (
        <div className={classNames('hidden', 'md:block', 'select-none')}>
          <ImageWrapper
            layout="fill"
            priority={false}
            objectPosition="top"
            field={backgroundImageField as SizedImageField}
            imageClass={classNames('w-full', 'h-full', 'object-cover', 'absolute', '-z-1')}
          />
        </div>
      )}
      {hasBackgroundMobileImage && (
        <div className={classNames('block', 'md:hidden', 'select-none')}>
          <ImageWrapper
            layout="responsive"
            priority={false}
            field={backgroundMobileImageField as SizedImageField}
            imageClass={classNames('w-full', '!h-full', 'object-cover')}
          />
        </div>
      )}
      <Container
        innerRef={innerRef}
        className={classNames(
          'block',
          'relative',
          additionalClassNames,
          getThemeClasses(theme),
          {
            'md:bg-none': isSplitTheme && hasBackgroundImage, // Remove the split-color theme on medium and larger screens that have background image
          },
          hasBackgroundImage ? 'md:bg-transparent' : '' // Apply transparent background if there is an background image and the screen is medium or larger
        )}
        dataComponent={dataComponent}
        theme={theme}
        dataOverlapClass={overlapClassNames}
      >
        <div className={classNames('container-content-padding-x', 'md:space-y-12', 'space-y-8')}>
          <HeadingWrapper headingClassName={headerClassName} {...fields} />
          {children}
        </div>
      </Container>
    </div>
  );
};

const LayoutPageSection = ({ fields, rendering }: LayoutPageSectionProps): JSX.Element => {
  const [isSubnav, setIsSubNav] = useState(false);
  const [nextSectionClass, setNextSectionClass] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const main = ref.current;

    const prev = main?.previousElementSibling;

    setNextSectionClass(
      prev?.getAttribute('data-next-spacing') ||
        prev?.querySelector('[data-next-spacing]')?.getAttribute('data-next-spacing') ||
        ''
    );
    setIsSubNav(
      main?.previousSibling?.contains(
        document.querySelector('[data-component="authorable/navigation/subnavigation"]')
      ) || false
    );
  }, []);

  const additionalClasses = isSubnav ? classNames('py-12') : classNames('py-8 md:py-16 lg:py-30');

  const themeValue = getEnum<Theme>(fields?.backgroundStyle);

  if (!fields) {
    return <MissingDataSource />;
  }

  return (
    <LayoutPageSectionShell
      innerRef={ref}
      theme={themeValue}
      additionalClassNames={additionalClasses}
      innerClassNames={nextSectionClass}
      dataComponent="authorable/layout/layout-page-section"
      {...fields}
    >
      <PlaceholderWrapper wrapperSize="normal" name="BCBSAZ-Page-Section" rendering={rendering} />
    </LayoutPageSectionShell>
  );
};

export default LayoutPageSection;
