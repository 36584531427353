// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import AutocompleteDropdown from 'components/helpers/AutocompleteDropdown/AutocompleteDropdown';
// import Button from 'components/helpers/Button/Button';
import Container from 'components/helpers/Container/Container';
import FormSelectList from 'components/helpers/FormElements/FormSelectList/FormSelectList';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { useEffect, useState, ChangeEvent } from 'react';
//https://react-tooltip.com/docs/getting-started
import { Tooltip } from 'react-tooltip';
import PriorAuthLookupDefaultResults from './PriorAuthLookupDefaultResults';
// import { useState } from 'react';
//type FindDoctorProps = Sitecore.Override.ComponentBase
export type PriorAuthLookupProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup;
export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages;

export type PriorAuthPlanType = Sitecore.Override.ItemEx &
  Feature.Data.Priorauth.PriorauthLookupOption.Fields.PriorauthLookupOption;

const PriorAuthLookup = ({ fields, rendering }: PriorAuthLookupProps): JSX.Element => {
  const [dateOfServiceProcedure, setDateOfServiceProcedure] = useState<string>('');
  const [memberId, setMemberId] = useState<string>('');
  const [providerNpiNumber, setProviderNpiNumber] = useState<string>('');

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDateOfServiceProcedure(value);
    console.log('Date of Service Procedure:', value);
  };

  const handleMemberIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMemberId(value);
    console.log('Member ID:', value);
  };

  const handleProviderNpiChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setProviderNpiNumber(value);
    console.log('Provider NPI Number:', value);
  };

  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  //state management: plan type options
  const [selected, setSelected] = useState<string | null>(fields?.planType[0]?.name);

  //state management: plantype form component
  const [selectionData, setSelectionData] = useState<Sitecore.Override.ItemEx | null>(
    fields?.planType[0]
  );

  const resultMessages = fields.defaultResults as unknown as ResultMessages;

  const minHeight = 'min-h-[650px]';
  const searchIcon = fields?.searchIcon?.value?.src;
  console.log('this is a prior auth component', fields);
  console.log('Searchicon===>', searchIcon);
  console.log('this is a prior auth result messages', resultMessages);
  console.log('selectionData', selectionData);

  const componentAnchorId = rendering?.uid;

  // priorauth-options-section logic
  useEffect(() => {
    setDefaultItem();
  }, []);

  function updateSelection(value: string) {
    console.log('updateSelection called', value);

    const selectedPlantype = fields?.planType.filter((el) => el.name === value);

    if (selectedPlantype != null || selectedPlantype != undefined) {
      setSelectionData(selectedPlantype[0]);
      setSelected(value);
    }
  }

  function setDefaultItem() {
    const defaultSelection = fields?.planType.filter((el) => el.name.toLowerCase() === 'select');

    if (defaultSelection != null || defaultSelection != undefined) {
      setSelectionData(defaultSelection[0]);
      setSelected(defaultSelection[0]?.name);
    }
  }

  function clearResults() {
    console.log('clearResults called');
  }

  return (
    // the main container that has the theme
    <Container
      theme="theme-blue--light"
      dataComponent="authorable/priorauthlookup"
      className={classNames('')}
      id={componentAnchorId}
      fullWidth={true}
    >
      {/* the main element that controls the padding for all content */}
      <div
        className={classNames(
          'priorauth-content-body',
          'container-content-padding-x',
          'md:space-y-12',
          'space-y-8'
        )}
      >
        {/* the heading section of the form */}
        <section className={classNames('priorauth-heading')}>
          <h2 className={classNames('text-5xl leading-[70px] font-light')}>
            {fields?.priorAuthTitle?.value}
          </h2>
        </section>

        <section className={classNames('priorauth-options-section')}>
          {/* <label htmlFor={`${componentAnchorId}-selectLabel`}>{fields?.optionsLabel?.value}</label> */}

          <FormSelectList
            id={`${componentAnchorId}-selectLabel`}
            name={`${componentAnchorId}-selectLabel`}
            label={fields?.optionsLabel?.value}
            placeholder={undefined}
            className={classNames('max-w-[50%]')}
            options={fields?.planType.map((opt) => {
              return {
                value: opt.name,
                label: opt.name,
              };
            })}
            defaultValue={{ value: fields?.planType[0]?.name, label: fields?.planType[0]?.name }}
            isSearchable={false}
            callBackChange={updateSelection}
            value={selected}
          />
        </section>

        {/* the form section */}
        <div
          className={classNames(
            'priorauth-form-shell',
            'rounded-lg bg-white shadow-card',

            minHeight
          )}
        >
          <div
            className={classNames(
              'border-b border-gray border-l border-r  md:p-8 rounded-b-lg p-5 text-gray-dark border-t rounded-t-lg',
              minHeight
            )}
          >
            <div
              className={classNames(
                'priorauth-form-body',
                'grid',
                'grid-cols-1',
                'gap-4',
                'md:grid-cols-2',
                'md:gap-5',
                'lg:gap-12',
                'space-y-6',
                'md:space-y-0',
                'relative'
              )}
            >
              <div className={classNames('mb-10', 'md:mb-0', 'space-y-6')}>
                PriorAuthLookup Form Component <br />
                <div>
                  <i data-tooltip-id="tooltip-1">tooltip</i>
                  <Tooltip
                    id="tooltip-1"
                    className="text-theme-text bg-theme-bg"
                    content="I am a tooltip"
                    place="bottom"
                    offset={10}
                  />
                </div>
                <div>
                  <label className="block mb-2">
                    Date of Service or Procedure (estimated if unsure)*
                  </label>{' '}
                  <br />
                  <input
                    type="date"
                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-[483px]"
                    value={dateOfServiceProcedure}
                    onChange={handleDateChange}
                    placeholder="MM/DD/YY"
                  />
                </div>
                <div>
                  <label>Procedure Keyword or CPT Code*</label> <br />
                  <AutocompleteDropdown searchIcon={searchIcon} />
                </div>
                <div>
                  <label className="block mb-2 mt-16">Member ID or Enrollment ID*</label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-[483px]"
                    placeholder="Enter ID"
                    value={memberId}
                    onChange={handleMemberIdChange}
                  />
                </div>
                {/* <div>
                  <label className="block mb-2">Account or Group Number*</label>
                  <input
                    type="input"
                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-[483px]"
                    placeholder="e.g., 000000"
                    value={accountOrGroupNumber}
                    onChange={handleAccountChange}
                  />
                </div> */}
                <div>
                  <label className="block mb-2">Provider NPI Number</label>
                  <input
                    type="input"
                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-[483px]"
                    placeholder="e.g., 0000000000"
                    value={providerNpiNumber}
                    onChange={handleProviderNpiChange}
                  />
                </div>
                <div>
                  <span>* Required</span>
                </div>
                <div>
                  <button className="flex items-center px-4 py-2 bg-gray-500 text-black rounded-xl hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 border border-gray-700">
                    Search
                    <svg
                      className="w-5 h-5 mr-2 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-4.35-4.35m1.35-5.65a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>

              <div
                className={classNames(
                  'prior-auth-results-section',
                  'mb-10',
                  'md:mb-0',
                  'space-y-6',
                  minHeight
                )}
              >
                <div
                  onClick={clearResults}
                  className={classNames(
                    'clear-prior-auth-results',
                    'flex justify-end',
                    'text-blue-peacock',
                    'text-base leading-[16px] font-regular',
                    'cursor-pointer'
                  )}
                >
                  {fields?.clearResultsTitle?.value}
                </div>
                <PriorAuthLookupDefaultResults fields={fields} rendering={rendering} />
              </div>
            </div>
          </div>
        </div>

        <div className={classNames('priorauth-footer', 'pb-6')}>
          <RichTextA11yWrapper field={fields?.footerText}></RichTextA11yWrapper>
        </div>
      </div>
    </Container>
  );
};

export default PriorAuthLookup;
